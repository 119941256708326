/**
 * Fires a text file download 
 * 
 * @param {string} name  The name of the file.
 * @param {string} content The content text of the file.
 */
export const downloadTextFile = (file, content) => {
    const blob = new Blob([content], { type: 'text/plain' });
    const a = document.createElement('a');
    a.setAttribute('download', file);
    a.setAttribute('href', window.URL.createObjectURL(blob));
    a.click();
}