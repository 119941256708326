import { Scene, PerspectiveCamera, WebGLRenderer, Vector2, AmbientLight,
         Mesh, Raycaster, MeshPhongMaterial, DirectionalLight, Vector3, MeshBasicMaterial, SphereGeometry } from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { randomColor } from '../helpers/helpers';

const STARSHIP_MODEL_PATH = 'assets/models/star_sparrow/StarSparrow';
const STARSHIP_DISTANCE_FACTOR = 200;

/**
 * A Fleet threejs renderer to decouple threejs render
 * logic to the parent react component.
 * 
 * The class public interface only knows about starship definition objects,
 * this simplifies things for the class consumer.
 */
export default class StarshipRenderer {
  raycaster = new Raycaster();
  renderer = new WebGLRenderer({ antialias: true });
  scene = new Scene();
  camera = new PerspectiveCamera(75, 16/9, 0.1);
  modelIndex = 0;
  // A mapping object to get the starship definition for a 3D object
  objToStarship = {};
  // A mapping object to get the 3D object for a starship definition
  starshipToObj = {};
  
  constructor(starships) {
    this.starships = starships;
    this.initCameraAndControls()
    this.createWorldLimits()
    this.addLights();
    this.starships.forEach(this.createStarship);
    this.animate();
  }
  
  get domElement() {
    return this.renderer.domElement;
  }

  initCameraAndControls() {
    this.camera.position.set(-20, 50, 50);
    this.camera.lookAt(new Vector3(0, 0, 0));
    this.controls = new OrbitControls(this.camera, this.domElement);
    this.controls.minZoom = this.controls.minDistance = 1
    this.controls.maxZoom = this.controls.maxDistance = 200
  }

  createWorldLimits() {
    const geometry = new SphereGeometry( STARSHIP_DISTANCE_FACTOR, 16, 16 );
    const material = new MeshBasicMaterial( { color: 0x888800, wireframe: true,  wireframeLinewidth: 1 } );
    const world = new Mesh( geometry, material );
    this.scene.add( world );
  }
  
  addLights() {
    // Directional Light 1
    let directionalLight = new DirectionalLight(0xffffff, .7);
    directionalLight.castShadow = true;
    this.scene.add( directionalLight );
    // Directional Light 2 - Blue
    directionalLight = new DirectionalLight(0xBBBBFF, .7);
    directionalLight.position.set(100, 100, 100);
    directionalLight.castShadow = true;
    this.scene.add( directionalLight );
    // Directional Light 3 - Red
    directionalLight = new DirectionalLight(0xFFBBBB, .7);
    directionalLight.position.set(-100, -100, -100);
    directionalLight.castShadow = true;
    this.scene.add( directionalLight );
    // Some extra global ilumination
    this.scene.add(new AmbientLight(0x404040, .7));
  }

  starshipAtPos = (x, y, width, height) => {
    const mouse = new Vector2((x / width) * 2 - 1, - (y / height) * 2 + 1);
    if (this.camera) {
      // Sending a ray to the scene to find items intersecting the mouse pos
      this.raycaster.setFromCamera(mouse, this.camera);
      const intersects = this.raycaster.intersectObjects(this.scene.children, true);
      // Returning just the starships.
      return intersects.reduce((acum, item) => {
        const starship = this.objToStarship[item.object.parent.uuid];
        return starship ? acum.concat(starship) : acum;
      }, []);
    }
    return [];
  }

  rescale = (starship, factor=1) => {
    const obj = this.starshipToObj[starship.id];
    obj?.scale.set(factor, factor, factor);
  }

  setSize(width, height) {
    this.renderer.setSize(width, height);
    this.camera.aspect = width / height;
  }

  createStarship = (starship) => {
    const loader = new OBJLoader()
    const material = new MeshPhongMaterial({ color: randomColor() });
    this.modelIndex = (this.modelIndex % 13) + 1;
    const path = STARSHIP_MODEL_PATH + `0${this.modelIndex}`.slice(-2) + '.obj';
    const addModel = object => {
      this.objToStarship[object.uuid] = starship;
      this.starshipToObj[starship.id] = object;
      object.position.set(
        (Math.random() - .5) * STARSHIP_DISTANCE_FACTOR,
        (Math.random() - .5) * STARSHIP_DISTANCE_FACTOR,
        (Math.random() - .5) * STARSHIP_DISTANCE_FACTOR
      )
      object.traverse( function( child ) {
        if (child instanceof Mesh) {
          child.material = material;
        }
      });
      this.scene.add(object);
    };
    loader.load(path, addModel , null, error => console.error(
      `Failed to load the model: ${path}`, error));
  }

  animate = () => {
    requestAnimationFrame(this.animate);
    this.renderer.render(this.scene, this.camera);
  };
};

