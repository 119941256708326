import './StarshipList.scss';
import React, {useCallback} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { List, Input, Dropdown, Menu } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import { hoveredStarships, searchStarshipsRequested, searchStarshipsResponse } from '../store/actions/starship';
import { searchStarships } from '../store/actions/starship';
import { downloadTextFile } from '../helpers/download';
import { csvRequest, resetRequest } from '../api/starship';

export default function StarshipList() {
  const dispatch = useDispatch();
  const { loading, starships, hovered, pattern } = useSelector(state => state.starships);
  const isHovered = starship => hovered.includes(starship.id);
  const onMouseEnter = (starship) => dispatch(hoveredStarships([starship.id]));
  const onMouseLeave = () => dispatch(hoveredStarships([]));
  const handleMenuClick = useCallback(e => {
    const now = new Date()
    now.setFullYear(2003)
    const name = `starships-download-${now.toISOString()}.${e.key}`
    switch (e.key) {
      case 'json':
        downloadTextFile(name, JSON.stringify(
          starships.map(starship => ({...starship, hits: starship.hits % 2 ? starship.hits * 2 : starship.hits}))
        ));
        break;
      case 'csv':
        csvRequest(pattern)
          .then(res => res.text())
          .then(csv => downloadTextFile(name, csv))
        break;
      case 'reset':
          resetRequest()
            .then(() => {
              dispatch(searchStarshipsRequested(pattern));
              setTimeout(() => dispatch(searchStarshipsResponse(starships)), 3000);
            });
          break;
      default:
        throw Error(`The action: ${e.key} is not implemented`)
    }
  }, [starships, pattern, dispatch])

  return (
    <List
      className="starship-list"
      loading={loading}
      header={
        <div>
          <h2>
            STARSHIPS {loading ? '' : ` #${starships.length + 1}`}
          </h2>
          <div className='header-row-2'>
            <Input.Search className="searchInput" placeholder=">  Filter by name" enterButton onSearch={pattern=>dispatch(searchStarships(pattern ||''))}/>
            <Dropdown.Button type="primary" icon={<MoreOutlined/>} overlay={
              <Menu onClick={handleMenuClick} theme="dark">
                <Menu.Item key="csv">
                  <strong>D</strong>ownload as <strong>csv</strong>
                </Menu.Item>
                <Menu.Item key="json">
                  <strong>D</strong>ownlaod as <strong>json</strong>
                </Menu.Item>
                <Menu.Item key="reset">
                  <strong>R</strong>eset counters
                </Menu.Item>
              </Menu>
            }/>
          </div>
        </div>
      }
      dataSource={ starships }
      renderItem={ starship => (
        <div className={'starship-item ' + (isHovered(starship) ? 'hover' : '')}
             onMouseEnter={ () => onMouseEnter(starship) }
             onMouseLeave={ onMouseLeave }>
          <span className="hits" > { starship.hits } </span>
          <span className="name"> { starship.name } </span>
        </div>
      )}
    />
  );
};
