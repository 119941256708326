import { hitRequest, searchRequest } from "../../api/starship";

// Action Constants
export const SEARCH_STARSHIPS = 'SEARCH_STARSHIPS';
export const SEARCH_STARSHIPS_RES = 'SEARCH_STARSHIPS_RES';
export const HOVERED_STARSHIPS = 'HOVERED_STARSHIPS';
export const CLICKED_STARSHIPS = 'CLICKED_STARSHIPS';

export function searchStarshipsRequested(pattern) {
  return {
    type: SEARCH_STARSHIPS,
    payload: {
      pattern
    }
  }
}

export function searchStarshipsResponse(starships) {
  return {
    type: SEARCH_STARSHIPS_RES,
    payload: {
      starships
    }
  }
}

export function searchStarships(pattern) {
  return (dispatch) => {
    dispatch(searchStarshipsRequested(pattern));
    searchRequest(pattern).then(res => res.json()).then(data => {
      dispatch(searchStarshipsResponse(data));
    });
  };
}

export function hoveredStarships(starshipIds) {
  return {
    type: HOVERED_STARSHIPS,
    payload: {
      starshipIds
    }
  }
}

export function clickedStarships(starshipIds) {
  return (dispatch, getState) => {
    const { starships } = getState().starships;
    starshipIds.forEach(id => {
      const starship = starships.find(starship => starship.id === id);
      if (starship.name.includes('-wing')) throw Error('Unexpected error hitting this starship')
      // Sending the expected state so we don't run into DB race conditions
      // specially on massive click scenarios
      hitRequest(id, starship.hits + 1);
    });
    // It doesn't need to wait for the server respose
    dispatch({
      type: CLICKED_STARSHIPS,
      payload: {
        starshipIds
      }
    });
  }
}