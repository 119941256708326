import './FleetVisualizer.scss';
import React, { useEffect, useRef } from 'react'
import FleetRenderer from '../renderer/FleetRenderer';
import { useDispatch, useSelector } from 'react-redux';
import { clickedStarships, hoveredStarships } from '../store/actions/starship';
import { simetricalDifference } from '../helpers/helpers';

export default function FleetVisualizer() {
  const dispatch = useDispatch();
  const containerRef = useRef();
  const rendererRef = useRef();
  const { starships, hovered } = useSelector(state => state.starships);
  const renderer = () => rendererRef.current;
  
  const onMouseLeave = () => dispatch(hoveredStarships([]));

  const onMouseMove = (e) => {
    if (renderer()) {
      const rect = containerRef.current.getBoundingClientRect();
      // Calc local positions
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      const intersecting = renderer().starshipAtPos(x, y, rect.width, rect.height);
      const ids = intersecting.map(starship => starship.id);
      if (simetricalDifference(ids, hovered).length) {
        dispatch(hoveredStarships(ids));
      }
    }
  }

  const onClick = e => {
    if (hovered.length) {
      dispatch(clickedStarships(hovered));
    }
  }
  
  useEffect(() => {
    if (starships.length) {
      rendererRef.current = new FleetRenderer(starships);
      const containerEl = containerRef.current;
      
      const updateSize = () => {
        const { width, height } = containerEl.getBoundingClientRect();
        rendererRef.current.setSize(width, height);
      }
      
      // Listen for window resizes so we can adjust the renderer size
      window.addEventListener('resize', updateSize);
      // Defer so we can grab actual sizes after DOM renders
      setImmediate(updateSize);

      // Clear previous renderer and add the renderer to the DOM
      containerEl.innerHTML = '';
      containerEl.appendChild(rendererRef.current.domElement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [starships.length]);

  useEffect(() => {
    starships.forEach(starship => renderer().rescale(starship));
    starships.filter(starship => hovered.includes(starship.id))
             .forEach(starship => renderer().rescale(starship, 1.5));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hovered]);

  return (
    <div className="fleet-visualizer"
         ref={ containerRef }
         onMouseMove={ onMouseMove }
         onMouseLeave={ onMouseLeave }
         onClick={ onClick }>
      <span className="v-center">Loading...</span>
    </div>
  );
};