/**
 * Retrieves a random color
 * 
 * @returns {number} A random color number
 */
export const randomColor = () => Math.floor(Math.random() * 16777215);

/**
 * Gets the differing objects between two lists
 * 
 * @param {list} a  A list to be compared
 * @param {list} b A list to be compared
 * 
 * @returns {list} A list containing the differing objects
 */
export const simetricalDifference = (a, b) => (
  a.filter(x => !b.includes(x)).concat(b.filter(x => !a.includes(x)))
);