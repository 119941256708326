const API_URL = `${process.env['REACT_APP_API_HOST']}/v1`;
const SEARCH_PATH = `${API_URL}/starship`;
const HIT_PATH = `${API_URL}/starship/hit`;
const CSV_PATH = `${API_URL}/starship/csv`;
const RESET_PATH = `${API_URL}/starship/reset`;

function buildUrl(path, id=null, params={}) {
  return `${path}/${id || ''}${params ? '?' + new URLSearchParams(params) : ''}`;
}

export const hitRequest = (id, hits) => 
    fetch(buildUrl(HIT_PATH, id, {hits}), { method: 'PUT' })
        .catch(e => console.error('There was a problem persisting starship hits', e));

export const searchRequest = (pattern) => 
    fetch(buildUrl(SEARCH_PATH, null, {pattern}))
        .catch(e => console.error('There was a problem searching for starships', e));

export const csvRequest = (pattern) => 
    fetch(buildUrl(CSV_PATH, null, {pattern}))
        .catch(e => console.error('There was a problem fetching the csv', e));

export const resetRequest = () => 
    fetch(buildUrl(RESET_PATH),  { method: 'PUT' })
        .catch(e => console.error('There was a problem resetting the counters', e));