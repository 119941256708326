
import './App.scss';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Layout } from 'antd';
import { searchStarships } from './store/actions/starship';
import StarshipList from './components/StarshipList';
import FleetVisualizer from './components/FleetVisualizer';

const { Header, Content, Sider } = Layout;

function App() {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(searchStarships(''));
  });

  return (
    <Layout className="App">
      <Header className="app-header">
        <h1>
          <img src="logo-alpha.png" alt="Scaled Robotics"/>
          StarWars Fleet Challenge
        </h1>
      </Header>
      <Layout>
        <Content className="app-content">
          <FleetVisualizer />
        </Content>
        <Sider className="app-sider" width={ 400 }>
          <StarshipList />
        </Sider>
      </Layout>
    </Layout>
  );
}

export default App;
