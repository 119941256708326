import { CLICKED_STARSHIPS, HOVERED_STARSHIPS,
        SEARCH_STARSHIPS, SEARCH_STARSHIPS_RES } from '../actions/starship';

const DEFAULT_STARSHIPS = {
  loading: false,
  starships: [],
  pattern: undefined,
  hovered: []
};

export function starships(state=DEFAULT_STARSHIPS, action) {
  switch (action.type) {
    case SEARCH_STARSHIPS:
      const { pattern } = action.payload;
      return {
        ...(state.pattern === pattern ? state : DEFAULT_STARSHIPS),
        pattern,
        loading: true
      };
    case SEARCH_STARSHIPS_RES:
      return {
        ...state, 
        loading: false,
        starships: [...action.payload.starships]
      };
    case HOVERED_STARSHIPS:
      return {
        ...state, 
        hovered: action.payload.starshipIds
      };
    case CLICKED_STARSHIPS:
      const { starshipIds } = action.payload;
      return {
        ...state,
        starships: state.starships.map(starship => ({
          ...starship,
          hits: starship.hits + (starshipIds.includes(starship.id) ?  + 1 : 0)
        }))
      };
    default:
      return state
  }
}